.inf-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.inf-image {
  max-width: 25%;
  height: auto;
  border-radius: 10px;
  margin-right: 50px;
}

.inf-content {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.inf-heading {
  color: #333;
  margin-bottom: 20px;
}

.inf-points {
  padding: 0;
  list-style-type: none;
  text-align: left;
  margin-bottom: 20px;
}

.inf-points li {
  margin-bottom: 10px;
}

.beautiful-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.beautiful-button:hover {
  background-color: #0056b3;
}

@media only screen and (max-width: 768px) {
  .inf-section {
    flex-direction: column;
  }
  .inf-image {
    max-width: 50%;
  }
}

.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.banner {
  text-align: center;
  background-color: rgb(124, 197, 16);
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid black;
}

.short-intro {
  text-align: center;
  max-width: 600px;
  font-weight: 600;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: xx-large;
  box-shadow: 10px 10px 10px 10px rgb(84, 83, 83) 3;
}

/* Responsive design */
@media (min-width: 768px) {
  .welcome-container {
    padding: 40px;
  }

  .banner {
    padding: 40px;
    margin-bottom: 30px;
  }

  .short-intro {
    font-size: 1.1rem;
  }
}
:root {
  --primary-color: rgb(124, 197, 16);
  --secondary-color: #007bff;
  --text-color: #333;
  --background-color: #f8f9fa;
  --section-padding: 40px;
  --font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  color: var(--text-color);
  background-color: var(--background-color);
  line-height: 1.6;
}

.home-page-content section {
  padding: var(--section-padding);
  margin: auto;
  max-width: 1200px;
}

.home-page-content h2 {
  color: var(--primary-color);
  margin-bottom: 20px;
}

.home-page-content p {
  margin-bottom: 20px;
}

/* Responsive grid layout for sections */
@media screen and (min-width: 768px) {
  .home-page-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .success-stories,
  .meet-our-team {
    grid-column: 1 / 3;
  }
}

/* Enhancements for larger screens */
@media screen and (min-width: 1024px) {
  .home-page-content {
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
  }

  .success-stories,
  .featured-jobs,
  .meet-our-team,
  .how-we-work {
    grid-column: span 2;
  }
}

/* Hover effects and transitions for interactive elements */
section:hover {
  transform: scale(1.02);
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Accessibility and focus styles for better navigation */
section:focus-within {
  outline: 3px solid var(--secondary-color);
  outline-offset: 3px;
}

.mvv-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 800px;
  padding: 20px;
}

.vision {
  background-color: #f0f4f8;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 20px;
  transition: transform 0.3s ease;
}

.vision:hover {
  transform: translateY(-5px);
}

.vision h2 {
  color: #191a1b;
  margin-bottom: 15px;
}

.vision p,
.vision .ul {
  color: #333;
  line-height: 1.6;
}

.vision .ul {
  list-style-type: none;
  padding: 0;
}

.vision .ul li {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%230056b3" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM7.5 4.5a.5.5 0 0 0-1 0v5a.5.5 0 0 0 1 0v-5zm.5 6.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/></svg>')
    no-repeat left center;
  padding-left: 25px; /* Adjust based on actual icon size */
  margin-bottom: 10px;
  background-size: 1em;
}

.vision strong {
  color: black;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .mvv-container {
    padding: 10px;
  }

  .vision {
    margin: 10px 0;
    padding: 15px;
  }
}

/* Enhance accessibility */
.vision:focus-within {
  outline: 2px solid rgb(34, 32, 32);
  outline-offset: 2px;
}

.partner-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: auto;
  max-width: 960px;
  padding: 20px;
}

.services-offered,
.why-partner {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: all 0.3s ease-in-out;
}

.services-offered:hover,
.why-partner:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

h2 {
  color: green;
  margin-bottom: 15px;
}

.services-offered ul {
  list-style-type: disc;
  margin-left: 20px;
}

.services-offered li,
.why-partner p {
  color: #333;
  line-height: 1.6;
  margin-bottom: 10px;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .partner-container {
    flex-direction: column;
  }
}

/* Enhancements for accessibility */
.services-offered:focus-within,
.why-partner:focus-within {
  outline: 3px solid green;
  outline-offset: 3px;
}

.header-container {
  background-color: #0056b3; /* Deep blue background */
  color: #ffffff; /* White text for contrast */
  text-align: center; /* Center align all content */
  padding: 40px 20px; /* Padding around the content */
  border-radius: 8px; /* Optional: rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  margin: 20px; /* Margin around the container */
}

.main-heading {
  font-size: 2rem; /* Larger text for the main heading */
  margin-bottom: 20px; /* Space between the heading and subheading */
}

.sub-heading {
  font-size: 1.2rem; /* Slightly larger text for the subheading */
  margin-bottom: 30px; /* Space between the subheading and button */
  max-width: 600px; /* Maximum width for better readability */
  margin-left: auto; /* Centering the subheading */
  margin-right: auto; /* Centering the subheading */
}

.beautiful-button {
  background-color: #ffc107; /* Bright yellow background for the button */
  color: #333; /* Dark text for contrast */
  border: none; /* Remove default border */
  padding: 10px 20px; /* Padding inside the button */
  font-size: 1rem; /* Text size inside the button */
  cursor: pointer; /* Change mouse cursor to indicate clickability */
  border-radius: 5px; /* Rounded corners for the button */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover effects */
}

.beautiful-button:hover {
  background-color: #ffca28; /* Lighter yellow on hover */
  transform: scale(1.05); /* Slightly enlarge button on hover */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .header-container {
    padding: 20px 10px; /* Reduce padding on smaller screens */
  }
  .main-heading {
    font-size: 1.5rem; /* Adjust heading size for smaller screens */
  }
  .sub-heading {
    font-size: 1rem; /* Adjust subheading size for smaller screens */
  }
}
